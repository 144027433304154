.wrapper {
    height: 100%;
    display: grid;
}

.title {
    text-align: center;
    margin: 8px 0 8px;
}

.canvas {
    border: 1px solid grey;
    margin: auto;
}

.controls {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 16px;
}

.controls > button {
    padding: 16px 32px;
    border: 0;
    border-radius: 500px;
    background: #0375fe;
    color: #fff;
    font-weight: bold;
    font-size: 18px;
    cursor: pointer;
    transition: all 0.2s ease;
}

.controls > button:hover {
    background: #1d81fa;
}

.controls > label {
    display: flex;
}

.step-counter {
    min-width: 100px;
}

@media (prefers-color-scheme: dark) {
    .wrapper {
        background: #222;
        color: white;
    }
}
